import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  padding:10px 0px 5px 0px;
  background-color:#FFF;
  /* border-top: solid 7px #6C59BF; */
  box-shadow:0px 0px 7px #CCC;
  
  @media(max-width: 414px) {
    /* border-top: none; */
  }
`
export const MainLogo = styled.div`
  width:200px;

  & img{
    width:100%;
  }

  @media(max-width: 414px) {
    width:170px;
  }
`
export const Copyright = styled.div`
  font-size:10px;

  @media(max-width: 414px) {
    
  }
`
export const Title = styled.div`
  font-size:12px;
  font-weight:bold;
  padding: 0px 0px 10px 0px;


  @media(max-width: 414px) {
    
  }
`