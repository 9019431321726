import moment from 'moment'
const now = moment().format('YYYY-MM-DD')

const formatArrayToGQL = array => {
  return JSON.stringify(array).replace(/"([^"]+)":/g, '$1:')
}

function where(
  {
    genreId,
  }
) {
  //console.log(startDate, endDate)
  let params = ``
  if (genreId && genreId.length > 0) {
    if (genreId[0] === '0') params += ``
    else params += `genreId: { in:${formatArrayToGQL(genreId)}} , `;
  }
  return params
}

export const GET_RANKING = (filter) => `
{
  ranking(
    where: {
      ${where(filter)}
      businessHours: false, 
      countryId: {eq: "1"}, 
      stationDate: {between: ["${now}", "${now}"]}
    }
  ) {
    normal {
      position
      data {
        audioId
        audio
        artist
        genre
        executions
        pictures {
          path
          thumbnail
        }
      }
    }
    byGenre {
      genreName
      genreAudios {
          audioId
          executions
          pictures {
              path
              thumbnail
          }
      }
    }
  }
}
`