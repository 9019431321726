import React, { useState, useEffect, useCallback } from 'react';
import {
  useParams
} from "react-router-dom";
import {
  Loading, Container, Line, MainPositionInfo, Position,
  Avatar, MusicInfo, MainMusicData, MusicData,
  Music, Artist, Genre, Plays
} from "./styled"
import { getRanking } from './services'
import { IMAGEPATH } from '../../services/api';

const Table = () => {


  let { id } = useParams()

  const [charts, setCharts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataRanking = useCallback(async () => {
    const charts = await getRanking(id ? { genreId: [`${id}`] } : 0)
    setCharts(charts)
    setLoading(false)
  }, [id])

  useEffect(() => {
    getDataRanking()
  }, [getDataRanking])

  return (
    <>
      {loading && <Loading>Carregando...</Loading>}
      <Container>

        {charts?.length > 0 && charts.map(
          line => (
            <Line key={line.key}>
              <MainPositionInfo>
                <Position>{line.position}º</Position>
                <MainMusicData>
                  {line && line.data && line.data.map((chart, i) => (
                    <MusicData key={i} isMultiple={line.data.length > 1 && i < line.data.length - 1}>
                      <Avatar>
                        <img src={`${IMAGEPATH}${chart.pictures[0].thumbnail}`} alt='audiency-charts' />
                      </Avatar>
                      <MusicInfo>
                        <Music>{chart.audio}</Music>
                        <Artist>{chart.artist}</Artist>
                        <Genre>{chart.genre}</Genre>
                      </MusicInfo>
                    </MusicData>
                  ))}
                </MainMusicData>
              </MainPositionInfo>

              <Plays>{new Intl.NumberFormat('pt-BR').format(line.plays)}</Plays>
            </Line>
          ))
        }

      </Container>
    </>
  )
}

export default React.memo(Table)