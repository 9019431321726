import api from '../../services/api';
import { GET_RANKING } from './Graphql/queries';

export const getRanking = async filter => {
  // console.log(GET_RANKING(filter))
  // return
  const response = await api().post('', { query: GET_RANKING(filter) })
    .catch(error => console.log(error))
    .then(response => response)
  if (response === 401) return response
  if (response && response.data) {
    const { ranking } = response.data.data
    const { normal } = ranking
    const Data = await normal.map((item, i) => ({
      key: i,
      ...item,
      plays: item.data[0].executions
    }))
    return Data
  }

}