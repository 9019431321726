import React from 'react'
import { Container, MainLogo, Copyright, Title } from "./styled"
import Logo from '../../assets/logo.png'
import moment from 'moment'


const Header = () => {
  return (
    <a href='https://audiency.io' target="_blank" rel="noreferrer">
      <Container>
        <MainLogo>
          <img src={Logo} alt='logo-audiency' />
        </MainLogo>
        <Title>
          TOP 100 RÁDIOS BRASIL - {moment().format('DD/MM/YYYY HH:mm')}
        </Title>
        <Copyright>
          © AUDIENCY TECHNOLOGY 2021 - TODOS OS DIREITOS RESERVADOS
      </Copyright>
      </Container>
    </a>
  )
}

export default React.memo(Header)