import axios from 'axios';

//Production
export const SERVER_API = 'https://api.audiency.io'
// export const SERVER_API = 'https://homolog-api.audiency.io'
export const ENDPOINT = '/client'

//IMAGE PATHS
export const IMAGEPATH = 'https://api.audiency.io/client-rest/image?token='
// export const IMAGEPATH = 'https://homolog-api.audiency.io/client-rest/image?token='
export const IMAGESASSETS = 'https://assets.audiency.io/images'

export const formatArrayToGQL = array => {
  return JSON.stringify(array).replace(/"([^"]+)":/g, '$1:')
}

const api = () => {

  const api = axios.create({
    baseURL: `${SERVER_API}${ENDPOINT}`,
  })
  return api;
}

export default api;