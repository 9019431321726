import styled, { css } from 'styled-components'

const PrimaryColor = '#6C59BF'

export const Loading = styled.div`
  width:100%;
  padding:20px 0px;
  margin-top:120px;
  margin-bottom:20px;
  text-align:center;
  font-weight:bold;

  @media(max-width: 414px) {
    height:100%;
    margin-top:95px;
  }
`
export const Container = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  padding:0px 7px;
  margin-top:120px;
  margin-bottom:20px;
  height:650px;
  overflow-y:auto;
  
  /* background-color:aqua; */
  
  @media(max-width: 414px) {
    height:100%;
    margin-top:95px;
  }
`
export const Line = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
  background-color:#FFF;
  padding:7px;
  margin-bottom:3px;
  border-radius:4px;
  box-shadow:0px 0px 3px #DDD;

  @media(max-width: 414px) {
    padding:7px 3px;
  }
  `
export const MainPositionInfo = styled.div`
  display:flex;
  align-items:center;
  /* border:dashed 1px #CCC; */
  `
export const MainMusicData = styled.div`
  display:flex;
  flex-direction:column;
  /* background-color:aqua; */
  `
export const MusicData = styled.div`
  display:flex;

  ${props => props.isMultiple && css`
    margin-bottom:5px;
    /* border-bottom:solid 1px #EEE;
    padding-bottom:3px; */
  `}
  
  /* border:dashed 1px #CCC; */
  `
export const Position = styled.div`
  display:flex;
  justify-content:center;
  width:47px;
  font-weight:bold;

  @media(max-width: 414px) {
    font-size:13px;
    width:35px;
  }
`
export const Avatar = styled.div`
  width:50px;
  padding:0 10px 0 0;

  & img{
    width:100%;
    border-radius:50%;
  }
`
export const MusicInfo = styled.div`
  display:flex;
  flex-direction:column;
`
export const Music = styled.span`
  color:${PrimaryColor};
  font-weight:700;
  font-size:14px;
  
  @media(max-width: 414px) {
    max-width:28ch;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    font-size:12px;
  }
  `
export const Artist = styled.span`
  font-size:12px;
  color:#000;
  font-weight:700;

  @media(max-width: 414px) {
    font-size:11px;
  }
  `
export const Genre = styled.span`
  font-size:12px;
  color:#555;
`
export const Plays = styled.div`
  display:flex;
  justify-content:center;
  width:47px;
  font-weight:700;
  color:${PrimaryColor};
  padding:0px 10px;
  /* background-color:aqua; */

  @media(max-width: 414px) {
    font-size:13px;
    width:37px;
  }
`