import GlobalStyle from './styles/Global'
import Header from './components/Header'
import Table from './components/Table'
import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom"

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <GlobalStyle />
          <Header />
          <Table />
        </Route>
        <Route exact path="/genre/:id">
          <GlobalStyle />
          <Header />
          <Table />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
